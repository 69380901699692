import React from "react";
import ReactDOM from "react-dom";
import { graphql } from "gatsby";
import { NodesContext } from "./page";
import type { PageContext } from "./page";
import Layout, { Container, colors } from "./layout";
import { BlogPosts } from "./blocks/DatoCmsBlogHighlight";
import { Helmet } from "react-helmet";

type Props = {
  data: {
    datoCmsBlog: {
      originalId: string;
      locale: string;
      title: string;
      publishedOn: string;
      isoPublishedOn: string;
      writer: {
        name: string;
        title: string;
      }[];
      pageContent: string;
      excerpt: string;
      pageContentAfterExcerpt: string;
      seoMetaTags: { tags: any[] };
    };
    allDatoCmsBlog: {
      nodes: {
        originalId: string;
        locale: string;
        title: string;
        excerpt: string;
        slug: null | string;
      }[];
    };
  };
  pageContext: PageContext;
  location: { pathname: string };
};

const Title: React.FC = ({ children }) => {
  const divRef = React.useRef<HTMLDivElement>(null!);
  const titleRef = React.useRef<HTMLHeadingElement>(null!);

  const [size, setSize] = React.useState<
    undefined | { width: number; height: number }
  >(undefined);
  const [titleRect, setTitleRect] = React.useState<
    undefined | { x: number; y: number; w: number; h: number }
  >(undefined);
  React.useLayoutEffect(() => {
    var ro = new ResizeObserver(entries => {
      for (let entry of entries) {
        const cr = entry.contentRect;
        setSize({ width: cr.width, height: cr.height });
      }
    });
    ro.observe(divRef.current!);
    return () => {
      ro.disconnect();
    };
  }, []);
  React.useLayoutEffect(() => {
    const tick = () => {
      const rect = titleRef.current!.getBoundingClientRect();
      const rect0 = divRef.current!.getBoundingClientRect();
      setTitleRect({
        x: rect.left - rect0.left,
        y: rect.top - rect0.top,
        w: rect.width,
        h: rect.height,
      });
    };
    window.addEventListener("resize", tick, false);
    window.addEventListener("scroll", tick, false);
    const req = requestAnimationFrame(() => {
      tick();
    });
    return () => {
      window.removeEventListener("resize", tick, false);
      window.removeEventListener("scroll", tick, false);
      cancelAnimationFrame(req);
    };
  }, []);
  const [sy, setSY] = React.useState(0);
  React.useLayoutEffect(() => {
    const scroll = () => {
      const rect = divRef.current!.getBoundingClientRect();
      let y = 1 - (rect.top + rect.height) / (window.innerHeight + rect.height);
      if (y < 0) y = 0;
      if (y > 1) y = 1;
      setSY(y);
    };
    window.addEventListener("scroll", scroll, false);
    const req = requestAnimationFrame(() => {
      scroll();
    });
    return () => {
      window.removeEventListener("scroll", scroll, false);
      cancelAnimationFrame(req);
    };
  }, [size]);

  return (
    <>
      <div
        css={`
          position: relative;
          padding-top: 128px;
          padding-bottom: 128px;
          @media (max-width: 1024px) {
            padding-top: 50px;
            padding-bottom: 50px;
          }
          overflow: hidden;
        `}
      >
        <div
          ref={divRef}
          css={`
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
          `}
        >
          {typeof size !== "undefined" && typeof titleRect !== "undefined" && (
            <svg width={size.width} height={size.height}>
              <g
                fill="none"
                strokeWidth={1 / window.devicePixelRatio}
                stroke="#000"
              >
                <rect
                  css={`
                    transition: x 300ms ease-out;
                  `}
                  x={titleRect.x + (-sy + 0.5) * 50}
                  y={titleRect.y}
                  width={size.width * 2}
                  height={titleRect.h}
                  rx={17.4442}
                />
                <rect
                  css={`
                    transition: x 300ms ease-out;
                  `}
                  x={-35 + (sy - 0.5) * 50}
                  y={-25}
                  width={size.width}
                  height={size.height + 25 - 1}
                  rx={17.4442}
                />
              </g>
            </svg>
          )}
        </div>
        <Container
          css={`
            display: grid;
            grid-template-columns: 1fr 9fr 2fr;
            grid-gap: 100px;
            > h1 {
              grid-area: 1 / 2 / span 1 / span 1;
              padding: 47px;
              margin: -47px;
            }
            @media (max-width: 1024px) {
              display: block;
              margin-left: 25px;
              margin-right: 25px;
              > h1 {
                padding: 25px;
                margin: -25px;
                margin-right: 10px;
              }
            }
          `}
        >
          <h1
            ref={titleRef}
            css={`
              ${colors.ag.H1}
            `}
          >
            {children}
          </h1>
        </Container>
      </div>
    </>
  );
};

const Effect2: React.FC = ({ children }) => {
  const divRef = React.useRef<HTMLDivElement>(null!);
  const titleRef = React.useRef<HTMLHeadingElement>(null!);
  const [size, setSize] = React.useState<
    undefined | { width: number; height: number }
  >(undefined);
  const [titleRect, setTitleRect] = React.useState<
    undefined | { x: number; y: number; w: number; h: number }
  >(undefined);
  React.useLayoutEffect(() => {
    var ro = new ResizeObserver(entries => {
      for (let entry of entries) {
        const cr = entry.contentRect;
        setSize({ width: cr.width, height: cr.height });
      }
    });
    ro.observe(divRef.current!);
    return () => {
      ro.disconnect();
    };
  }, []);
  React.useLayoutEffect(() => {
    const tick = () => {
      const rect = titleRef.current!.getBoundingClientRect();
      const rect0 = divRef.current!.getBoundingClientRect();
      setTitleRect({
        x: rect.left - rect0.left,
        y: rect.top - rect0.top,
        w: rect.width,
        h: rect.height,
      });
    };
    window.addEventListener("resize", tick, false);
    window.addEventListener("scroll", tick, false);
    const req = requestAnimationFrame(() => {
      tick();
    });
    return () => {
      window.removeEventListener("resize", tick, false);
      window.removeEventListener("scroll", tick, false);
      cancelAnimationFrame(req);
    };
  }, []);
  return (
    <>
      <div ref={divRef}>
        <div
          css={`
            display: none;
            @media (max-width: 1024px) {
              display: block;
            }
            margin-left: -25px;
            margin-right: -25px;
            padding-left: 25px;
            padding-right: 25px;
            border-top: 1px solid #000;
            border-bottom: 1px solid #000;
            margin-top: 30px;
            margin-bottom: 30px;
            position: relative;
          `}
        >
          <div
            css={`
              padding-top: 91px;
              padding-bottom: 47px;
              display: flex;
              justify-content: center;
              align-items: center;
              padding-right: 20px;
            `}
          >
            <div
              ref={titleRef}
              css={`
                background: #ffffff;
                border: 1px solid #000000;
                box-sizing: border-box;
                border-radius: 17.4442px;
                max-width: 348px;
                padding: 31px 38px;
                @media (max-width: 1024px) {
                  padding: 20px;
                }
                z-index: 1;
                position: relative;
              `}
            >
              <h3
                css={`
                  ${colors.ag.H3}
                `}
              >
                {children}
              </h3>
            </div>
          </div>
          <div
            css={`
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
            `}
          >
            {typeof size !== "undefined" &&
              typeof titleRect !== "undefined" && (
                <svg width={size.width} height={size.height}>
                  <g
                    fill="none"
                    strokeWidth={1 / window.devicePixelRatio}
                    stroke="#000"
                  >
                    <rect
                      x={titleRect.x + titleRect.w + 20}
                      y={titleRect.y - size.height}
                      width={size.width}
                      height={size.height}
                      rx={17.4442}
                    />
                  </g>
                  <circle
                    cx={titleRect.x + titleRect.w + 20}
                    cy={titleRect.y}
                    r={46 / 2}
                    fill="#FFCE2E"
                  />
                </svg>
              )}
          </div>
        </div>
      </div>
    </>
  );
};

const Body: React.FC<
  Omit<
    Props["data"]["datoCmsBlog"],
    "title" | "originalId" | "locale" | "seoMetaTags" | "isoPublishedOn"
  >
> = ({
  publishedOn,
  writer,
  pageContent,
  excerpt,
  pageContentAfterExcerpt,
}) => {
  const excerptDivRef = React.useRef<HTMLDivElement>(null!);
  const [rect, setRect] = React.useState<
    undefined | { x: number; y: number; w: number; h: number }
  >(undefined);
  React.useLayoutEffect(() => {
    const tick = () => {
      const { left, top, width, height } =
        excerptDivRef.current!.getBoundingClientRect();
      if (left === 0) return;
      setRect({ x: left, y: top, w: width, h: height });
    };
    window.addEventListener("resize", tick, false);
    window.addEventListener("scroll", tick, false);
    requestAnimationFrame(() => {
      tick();
    });
    return () => {
      window.removeEventListener("resize", tick, false);
      window.removeEventListener("scroll", tick, false);
    };
  }, []);
  const el = React.useMemo(
    () =>
      (typeof document !== "undefined"
        ? document.createElement("div")
        : undefined)!,
    []
  );
  React.useLayoutEffect(() => {
    document.body.appendChild(el);
    return () => {
      document.body.removeChild(el);
    };
  }, []);
  return (
    <>
      <Container>
        <div
          css={`
            display: grid;
            grid-template-columns: 1fr 7fr 4fr;
            grid-gap: 100px;
            > div:nth-child(1) {
              grid-area: 1 / 2 / span 1 / span 1;
            }
            > div:nth-child(2) {
              grid-area: 1 / 3 / span 1 / span 1;
            }
            @media (max-width: 1024px) {
              display: block;
            }
          `}
        >
          <div>
            <div
              css={`
                ${colors.ag["Body P Small"]}
                display: grid;
                grid-gap: 0.75em;
              `}
            >
              {writer.map(writer => (
                <>
                  <div>
                    <p>{writer.name}</p>
                    <div dangerouslySetInnerHTML={{ __html: writer.title }} />
                  </div>
                </>
              ))}
            </div>
            <div
              css={`
                height: 40px;
              `}
            />
            <div
              css={`
                ${colors.ag["Body P"]}
                p {
                  margin-bottom: 1em;
                }
                a {
                  ${colors.sans}
                  font-weight: bold;
                  font-size: 18px;
                  line-height: 22px;
                  letter-spacing: -0.015em;
                  text-decoration-line: underline;
                  color: #000;
                }
              `}
            >
              <div dangerouslySetInnerHTML={{ __html: pageContent }} />
              <Effect2>{excerpt}</Effect2>
              <div
                dangerouslySetInnerHTML={{ __html: pageContentAfterExcerpt }}
              />
            </div>
            <div
              css={`
                height: 107px;
              `}
            />
          </div>
          <div
            css={`
              display: flex;
              justify-content: center;
              align-items: center;
              @media (max-width: 1024px) {
                display: none;
              }
            `}
          >
            <div
              css={`
                background: #ffffff;
                border: 1px solid #000000;
                box-sizing: border-box;
                border-radius: 17.4442px;
                max-width: 348px;
                padding: 31px 38px;
                position: relative;
                z-index: 1;
              `}
              ref={excerptDivRef}
            >
              <h3
                css={`
                  ${colors.ag.H3}
                `}
              >
                {excerpt}
              </h3>
            </div>
          </div>
        </div>
      </Container>
      {el &&
        typeof rect !== "undefined" &&
        ReactDOM.createPortal(
          <div
            css={`
              position: fixed;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              pointer-events: none;
            `}
          >
            <svg width={window.innerWidth} height={window.innerHeight}>
              <g
                stroke="#000"
                strokeWidth={1 / window.devicePixelRatio}
                fill="none"
              >
                <rect
                  x={rect.x + rect.w + 14}
                  y={rect.y - 3 - window.innerHeight * 100}
                  width={window.innerWidth}
                  height={window.innerHeight * 100}
                  rx={17.4442}
                />
              </g>
              <circle
                cx={rect.x + rect.w}
                cy={rect.y}
                r={92 / 2}
                fill="#FFCE2E"
                stroke="none"
              />
            </svg>
          </div>,
          el
        )}
    </>
  );
};

const Blog: React.FC<Props> = ({
  pageContext,
  data: {
    datoCmsBlog: {
      title,
      publishedOn,
      isoPublishedOn,
      writer,
      pageContent,
      excerpt,
      pageContentAfterExcerpt,
      seoMetaTags,
    },
    allDatoCmsBlog: { nodes },
  },
  location: { pathname },
}) => {
  const { locale } = pageContext;
  const [nodes2, setNodes2] = React.useState<
    undefined | Props["data"]["allDatoCmsBlog"]["nodes"]
  >(undefined);
  React.useLayoutEffect(() => {
    setNodes2(
      nodes
        .filter(v => v.locale === locale && v.slug !== null && v.slug !== "")
        .sort((a, b) => {
          if (Math.random() < 0.5) return -1;
          return 1;
        })
        .slice(0, 2)
    );
  }, [nodes]);
  return (
    <>
      <Layout
        pageContext={pageContext}
        pathname={pathname}
        seoMetaTags={seoMetaTags}
      >
        <div>
          <Title>{title}</Title>
          <div
            css={`
              height: 50px;
            `}
          />
          <Body
            publishedOn={publishedOn}
            writer={writer}
            pageContent={pageContent}
            excerpt={excerpt}
            pageContentAfterExcerpt={pageContentAfterExcerpt}
          />
        </div>
        {typeof nodes2 !== "undefined" && (
          <div
            css={`
              background: #ededed;
              padding-top: 78px;
              padding-bottom: 158px;
              @media (max-width: 640px) {
                padding-top: 48px;
                padding-bottom: 25px;
              }
            `}
          >
            <Container>
              <h3
                css={`
                  ${colors.ag.H3}
                `}
              >
                {(() => {
                  if (locale === "fi") return "Lisää kirjoituksia";
                  if (locale === "en") return "More blog posts";
                })()}
              </h3>
              <div
                css={`
                  height: 40px;
                  @media (max-width: 640px) {
                    height: 21px;
                  }
                `}
              />
              <BlogPosts blogPosts={nodes2} />
            </Container>
          </div>
        )}
      </Layout>
      <Helmet>
        {writer.map(writer => (
          <meta name="author" content={writer.name} />
        ))}
        <meta
          name="publish_date"
          property="og:publish_date"
          content={isoPublishedOn}
        />
      </Helmet>
    </>
  );
};

const Blog1: React.FC<Props> = props => {
  const { nodes, locale } = props.pageContext;
  return (
    <NodesContext.Provider value={{ nodes, locale }}>
      <Blog {...props} />
    </NodesContext.Provider>
  );
};
export default Blog1;

export const query = graphql`
  query ($originalId: String!, $locale: String!) {
    datoCmsBlog(originalId: { eq: $originalId }, locale: { eq: $locale }) {
      originalId
      locale
      title
      publishedOn(formatString: "D.M.YYYY")
      isoPublishedOn: publishedOn(formatString: "YYYY-MM-DDTHH:mm:ssZ")
      writer {
        name
        title
      }
      pageContent
      excerpt
      pageContentAfterExcerpt
      seoMetaTags {
        tags
      }
    }
    allDatoCmsBlog {
      nodes {
        originalId
        locale
        title
        excerpt
        slug
      }
    }
  }
`;
